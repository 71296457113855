<template>
  <div>
    <TopMenuSubheadline :title="title" />
    <div v-if="isLoading">
      <Loader />
    </div>
    <div class="app-content-box bg-gray" v-else>
    <div class="wrapper mt-5 mb-5">
      <div class="container" v-if="donePlans.length">
        <div class="mt-4 d-flex justify-content-center w-100">
          <div class="trainings-links">
            <div  class="links-heading">
              <router-link class="text-dark" :to="{name: 'TrainingPlan'}">aktualny</router-link>
            </div>
            <div class="links-heading">
              <b><u>historia</u></b>
            </div>
          </div>
        </div>
        <div>
          <div class="mt-4 mb-4" v-for="(item, key) in donePlans" :key="key">
            <div class="history-list-wrapper">
              <div class="col-3">
                  <div class="history-list-icon mr-3">
                    <i class="fas fa-check"></i>
                  </div>
              </div>
              <div class="col-9">
                <div class="row">
                    <span class="history-notification">Ukończyłeś {{item.name}}. Brawo!</span>
                    <router-link
                      :to="{name:'TrainingPlanHistoryMore', params: {id: item.id}}"
                      class="text-dark">
                      <span class="history-summary"><u>Podsumowanie</u></span>
                    </router-link>
                </div>
              </div>
            </div>
            <hr class="section-spacer" />
        </div>
        </div>
      </div>
      <div v-else>
        <div class="mt-4 d-flex justify-content-center w-100">
          <div class="trainings-links">
            <div  class="links-heading">
              <router-link class="text-dark" :to="{name: 'TrainingPlan'}">aktualny</router-link>
            </div>
            <div class="links-heading">
              <b><u>historia</u></b>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <div class="history-notification text-center">Nie masz jeszcze żadnych ukończonych planów czytelniczych.</div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import Loader from '../components/Loader'
export default {
  name: 'TrainingPlanHistory',
  components: {
    TopMenuSubheadline,
    Loader
  },
  data () {
    return {
      title: 'Historia planu czytelniczego',
      isLoading: true,
      donePlans: []
    }
  },
  async created () {
    await this.$https('/plans', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        data.response.forEach(e => {
          if (e.status === 'done') {
            this.donePlans.push(e)
          }
        })
        this.isLoading = false
      })
      .catch(error => {
        console.log('', error)
      })
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .trainings-links {
  list-style-type: none;
  display: flex;
  width: 70%;
  justify-content: space-around;
}
.links-heading {
  font-size: 13px;
  text-align: center;
}

.history-list-wrapper {
  display: flex;
  align-items: center;
}
.history-list-icon {
  width: 45px;
  height: 45px;
  border: 1px solid #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.history-notification {
  font-weight: 700;
  line-height: 22px;
}
.history-summary {
  font-size: 14px;
}
</style>
